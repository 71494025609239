<app-back-title-layout [loading]="!formGroup">
  <ng-container *ngIf="formGroup">
    <section class="my-10">
      <form [formGroup]="formGroup" (ngSubmit)="submit()">
        <h4 class="text-xl font-bold">Course Details</h4>

        <div class="my-10 form-width">
          <label class="form-label leading-none">
            <span>Thumbnail</span>
            <a
              class="ml-1 text-primary-light"
              href="https://support.castlms.com/help/what-is-a-thumbnail"
              target="_blank"
            >
              <app-icon icon="help_outline"></app-icon>
            </a>
          </label>
          <div class="text-sm text-gray-600 mb-1">
            Recommended size is 220x220px. Accepts jpeg, png and gif.
          </div>

          <div class="mt-2 h-32 w-32 bg-purple-200">
            <app-upload-loading-wrapper
              #thumbnailInput
              [disabled]="formGroup.get('coverUri').disabled"
              (upload)="onUpload($event)"
              accept="image/jpeg,image/jpg,image/png,image/gif"
            >
              <div class="w-full h-full">
                <div
                  *ngIf="!formGroup.value.coverUri"
                  class="h-full text-purple-500 w-full flex flex-col items-center justify-center"
                >
                  <app-icon class="text-6xl" icon="image"></app-icon>
                </div>

                <img
                  *ngIf="formGroup.value.coverUri"
                  [src]="formGroup.value.coverUri"
                  alt=""
                  class="w-full object-contain h-full"
                />
              </div>
            </app-upload-loading-wrapper>
          </div>

          <div>
            <button
              type="button"
              [disabled]="formGroup.get('coverUri').disabled"
              (click)="thumbnailInput.click()"
              class="w-32 bg-purple-500 text-white mt-2 rounded"
            >
              {{ formGroup.value.coverUri ? "CHANGE" : "ADD IMAGE" }}
            </button>
          </div>
        </div>

        <div class="my-10 form-width">
          <label>
            <span class="form-label">Course Name </span>
            <input formControlName="name" class="form-control" />

            <app-form-control-error-layout
              [control]="formGroup.get('name')"
            ></app-form-control-error-layout>
          </label>
        </div>

        <div class="my-10 form-width">
          <label class="form-label leading-none"> Course Description </label>
          <div class="text-sm text-gray-600 mb-1">
            This will appear to your resource info card, right before opening
            this resource
          </div>

          <app-markdown-viewer-and-editor
            [autoResize]="false"
            editorClass="h-40 overflow-y-scroll"
            viewerClass="h-40 overflow-y-scroll border rounded p-2"
            formControlName="description"
          ></app-markdown-viewer-and-editor>
        </div>

        <!-- <hr class="my-10" /> -->

        <!-- <section class="my-10">
          <h4 class="text-xl font-bold">Certification</h4>

          <div *ngIf="formGroup.value.isWithCertification" class="text-sm mt-4">
            <span
              class="inline-block text-white bg-info-400 rounded-full px-2 py-1"
            >
              with certification
            </span>

            <p class="text-gray-500 my-5">
              Your course offers certification. Users will receive their
              certificate through the completion email
            </p>
          </div>

          <div
            *ngIf="!formGroup.value.isWithCertification"
            class="text-sm mt-4"
          >
            <p class="text-gray-500 my-5">
              Your course offers <b class="font-bold">NO</b> certification.
              Users will receive their certificate through the completion email
            </p>
          </div>

          <a
            class="text-primary"
            [routerLink]="['/_courses', 'certification']"
            [queryParams]="{ course_uuid: formGroup.value.courseUuid }"
            >edit certificate details</a
          >
        </section> -->

        <hr class="my-10" />

        <section class="my-10">
          <h4 class="text-xl font-bold">Email Notifications</h4>

          <a
            class="text-primary"
            [routerLink]="['/_courses', 'email-notifications']"
            [queryParams]="{ course_uuid: formGroup.value.courseUuid }"
            >edit email notification details</a
          >
        </section>

        <hr class="my-10" />

        <section class="my-10">
          <h4 class="text-xl font-bold">Course Appearance</h4>

          <a
            class="text-primary"
            [routerLink]="['/_courses', 'appearance']"
            [queryParams]="{ course_uuid: formGroup.value.courseUuid }"
            >edit course appearance</a
          >
        </section>

        <hr class="my-10" />

        <section class="my-10">
          <h4 class="text-xl font-bold">Publishing Details</h4>

          <a
            class="text-primary"
            [routerLink]="['/resources', 'profile', 'publish']"
            [queryParams]="{
              course_uuid: formGroup.value.courseUuid,
              resource_uuid: formGroup.value.resourceUuid
            }"
            >edit publishing details</a
          >
        </section>

        <hr class="my-10" />

        <section class="my-10">
          <h4 class="text-xl font-bold">Course Administrators</h4>

          <a
            class="text-primary"
            [routerLink]="['/_courses', 'permissions']"
            [queryParams]="{
              course_uuid: formGroup.value.courseUuid,
              resource_uuid: formGroup.value.resourceUuid
            }"
            >edit course administrators</a
          >
        </section>

        <hr class="my-10" />

        <section class="my-10">
          <h4 class="text-xl font-bold">Course Block Completion Reports</h4>

          <a
            class="text-primary"
            [routerLink]="['/_courses', 'block-completion-reports']"
            [queryParams]="{
              course_uuid: formGroup.value.courseUuid,
              resource_uuid: formGroup.value.resourceUuid
            }"
            >see course block completion reports</a
          >
        </section>

        <div class="my-3 flex flex-row-reverse">
          <button
            [disabled]="!formGroup?.valid"
            type="submit"
            class="btn btn-primary mx-5"
          >
            Save
          </button>

          <button
            (click)="deleteResource()"
            type="button"
            class="btn btn-danger mx-5"
          >
            Delete
          </button>
        </div>
      </form>
    </section>
  </ng-container>
</app-back-title-layout>
