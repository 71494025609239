import { Location } from "@angular/common";
import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  SimpleChanges,
} from "@angular/core";
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  UntypedFormArray,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { firstValueFrom, zip } from "rxjs";
import { criteriaWeightSum } from "src/app/app-common-module/form-validators/criteria-weight-sum.validator";
import { minLengthArray } from "src/app/app-common-module/form-validators/min-length-array.validator";
import { NotificationService } from "src/app/services/notification.service";
import { V1ExamsService, V1ExamChoice } from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-activity-question-new",
  templateUrl: "./activity-question-new.component.html",
  styles: [],
})
export class ActivityQuestionNewComponent implements OnInit {
  formGroup: UntypedFormGroup;

  loading: boolean = false;
  canUpdate: boolean = false;
  @Input() itemType: string;
  @Input() itemSubtype: string;
  @Input() insideSlide: boolean = false;
  @Input() sectionUuid: string;
  @Output() isLoading: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() itemUuid: EventEmitter<string> = new EventEmitter<string>();
  constructor(
    private v1ExamsService: V1ExamsService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private formBuilder: UntypedFormBuilder,
    private notifService: NotificationService
  ) {}

  async ngOnInit() {
    let {
      exam_uuid: examUuid,
      item_type: itemType,

      // Flag for disabling/enabling show results checkbox
      disable_show_results: disableShowResults,
    } = this.route.snapshot.queryParams;
    this.formGroup = this.formBuilder.group({
      text: [null, Validators.required],
      title: [""],
      type: [itemType || this.itemType],
      subtype: [this.itemSubtype || ""],
      mediaUrl: [null],
      hint: [null],
      instructions: [null],
      explanation: [null],
      required: [false],
      showResults: [
        {
          value: null,
          disabled: disableShowResults,
        },
      ],
      // Default value is true
      isGraded: [true],
      shuffleChoices: [false],
      weight: [null],
      neutralFeedbackMessage: [null],
      neutralFeedbackImageUrl: [null],
      correctFeedbackMessage: [null],
      correctFeedbackImageUrl: [null],
      incorrectFeedbackMessage: [null],
      incorrectFeedbackImageUrl: [null],
      choices:
        this.constructExamChoicesFormArray(itemType || this.itemType) || [],
      rubrics: this.constructExamRubricsFormArray(itemType || this.itemType),
      choiceSetFeedbacks: [[]],
      sectionUuid: [this.sectionUuid],
    });
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.itemType.currentValue !== changes.itemType.previousValue) {
      this.ngOnInit();
    }
  }
  constructExamChoicesFormArray(examItemType: string): UntypedFormArray {
    // If multiple choice
    if (
      examItemType == "MC" ||
      examItemType == "IC" ||
      examItemType == "CB" ||
      (examItemType == "PO" && this.itemSubtype !== "SU")
    ) {
      return this.formBuilder.array([], minLengthArray(1));
    } else if (examItemType == "FW" || this.itemSubtype === 'SU') {
      return this.formBuilder.array([], minLengthArray(0));
    } else if (examItemType == "SA" || examItemType == "LA") {
      return this.formBuilder.array([
        this.formBuilder.group({
          text: [null],
          choiceUuid: [null],
          imageUri: [null],
          audioUri: [null],
          // Force correctness
          isCorrect: [true],
          isDeleted: [false],
        }),
      ]);
    }
  }

  constructExamRubricsFormArray(itemType: string): UntypedFormArray {
    if (itemType === "ME") {
      return this.formBuilder.array([], criteriaWeightSum());
    }
  }

  async createItem() {
    let { exam_section_uuid: sectionUuid, splice_index: spliceIndex } =
      this.route.snapshot.queryParams;

    let examItems = await firstValueFrom(
      this.v1ExamsService.examsSectionsItemsList(
        sectionUuid || this.sectionUuid
      )
    );

    examItems.splice(spliceIndex, 0, {});

    await Promise.all(
      examItems
        .map((item, i) => {
          item.orderId = i;

          return item;
        })
        .filter((item) => item.uuid)
        .map((item) =>
          firstValueFrom(
            this.v1ExamsService.examsSectionsItemsUpdate(item.uuid, item)
          )
        )
    );

    return await firstValueFrom(
      this.v1ExamsService.examsSectionsItemsCreate(
        sectionUuid || this.sectionUuid,
        {
          ...this.formGroup.value,
          orderId: spliceIndex,
        }
      )
    );
  }

  async createChoice(itemUuid: string, choice: V1ExamChoice) {
    await firstValueFrom(
      this.v1ExamsService.examsSectionsItemsChoicesCreate(itemUuid, choice)
    );
  }

  // Main mathod for updating the exam item
  async createAll() {
    try {
      this.loading = true;
      this.isLoading.emit(this.loading);
      let item = this.formGroup.value.itemUuid
        ? { ...this.formGroup.value }
        : await this.createItem();
      if (this.itemSubtype !== "SU" && !this.formGroup.value.itemUuid)
        await this.createChoices(item.uuid);
      await this.createRubrics(item?.itemUuid || item.uuid);
      this.itemUuid.emit(item?.itemUuid || item.uuid);
      return { item };
    } catch (err) {
      throw err;
    } finally {
      this.loading = false;
      this.isLoading.emit(this.loading);
    }
  }

  async createChoices(itemUuid: string) {
    // Get choices information from the activity-type-poll component
    let choicesArray = this.formGroup.get("choices") as UntypedFormArray;

    let createChoiceControls = choicesArray?.controls?.filter(
      (control) => !control.value.isDeleted
    );

    // Handle choices

    if (createChoiceControls) {
      // For each choice form control

      for (let i = 0; i < createChoiceControls.length; i++) {
        let control = createChoiceControls[i];

        await this.createChoice(itemUuid, {
          longInput: control.value.text || "",
          shortInput: control.value.text || "",
          imageUrl: control.value.imageUri || "",
          audioUrl: control.value.audioUri || "",
          isCorrect: control.value.isCorrect,
          orderId: i,
        });
      }
      // await Promise.all(
      //   createChoiceControls.map((control, i) =>
      //     this.createChoice(itemUuid, {
      //       longInput: control.value.text || "",
      //       shortInput: control.value.text || "",
      //       imageUrl: control.value.imageUri || "",
      //       audioUrl: control.value.audioUri || "",
      //       isCorrect: control.value.isCorrect,
      //       orderId: i,
      //     })
      //   )
      // );
    }
  }

  async createRubrics(itemUuid: string) {
    // Get choices information from the activity-type-poll component
    let rubricFormArray = this.formGroup.get("rubrics") as UntypedFormArray;

    let createRubicFormControls = rubricFormArray?.controls?.filter(
      (control) => !control.value.isDeleted
    );

    if (createRubicFormControls) {
      await Promise.all(
        createRubicFormControls.map((control, i) =>
          firstValueFrom(
            this.v1ExamsService.examsSectionsItemsCriteriaCreate(itemUuid, {
              name: control.value.name,
              orderId: i,
              weight: control.value.weight / 100,
            })
          )
        )
      );
    }
  }

  async onSave() {
    let {
      // Flag to determine if we publish the resource on next.
      // Used for freedom-wall and file-upload resource types
      publish_resource_on_next: publishResourceOnNext,
      resource_uuid: resourceUuid,
    } = this.route.snapshot.queryParams;

    await this.createAll();

    // if (publishResourceOnNext) {
    //   this.router.navigate(["/resources", "publish"], {
    //     replaceUrl: true,
    //     queryParams: {
    //       resource_uuid: resourceUuid,
    //     },
    //     queryParamsHandling: "merge",
    //   });
    // }
    if (this.insideSlide) {
    } else {
      this.location.back();
    }

    this.notifService.addSuccess({
      title: "Question added!",
      message: "Question added successfully.",
    });
  }

  async onSaveAndCreateAnother() {
    let { splice_index: spliceIndex } = this.route.snapshot.queryParams;

    await this.createAll();

    this.router.navigate(["../select-item-type"], {
      relativeTo: this.route,
      queryParams: {
        splice_index: parseInt(spliceIndex + "") + 1,
      },
      replaceUrl: true,
      queryParamsHandling: "merge",
    });

    this.notifService.addSuccess({
      title: "Question added!",
      message: "Question added successfully.",
    });
  }
}
