<p class="font-bold" [ngClass]="{ 'text-center': insideSlide }">
  {{ formGroup.value.type | activityType }}
</p>

<div [formGroup]="formGroup" class="relative form-width">
  <div
    *ngIf="formGroup.disabled"
    class="absolute inset-0 bg-white bg-opacity-25 z-10"
  ></div>

  <!-- <div class="my-10">
        <label>
          <span class="form-label">Title</span>

          <input type="text" formControlName="title" class="form-control" />
        </label>

      </div> -->

  <div class="my-10">
    <label>
      <span class="form-label">Question</span>

      <div class="flex items-start justify-start">
        <div class="flex-1">
          <input type="text" formControlName="text" class="form-control" />
          <app-upload-loading-wrapper
            #uploader
            accept="image/*"
            text="UPLOAD IMAGE"
            [ngClass]="{
              hidden: !formGroup.value.mediaUrl
            }"
            (upload)="onUpload($event, formGroup.get('mediaUrl'))"
          >
            <div class="relative" *ngIf="formGroup.value.mediaUrl">
              <img
                [src]="formGroup.value.mediaUrl"
                class="w-full h-40 object-contain bg-gray-300 rounded"
              />

              <button
                type="button"
                (click)="onUploadClear(formGroup.get('mediaUrl'))"
                class="appearance-none pt-1 pb-2 pr-1 pl-2 z-auto rounded-bl-full hover:bg-red-500 text-white bg-gray-500 absolute top-0 right-0"
              >
                <app-icon icon="close"></app-icon>
              </button>
            </div>
            <!-- 
                <div
                  *ngIf="!formGroup.value.mediaUrl"
                  class="w-full h-40 flex flex-col items-center justify-center bg-gray-300 text-primary rounded"
                >
                  <app-icon icon="image" class="text-5xl"> </app-icon>
    
                  <span>ADD IMAGE</span>
                </div> -->
          </app-upload-loading-wrapper>

          <div
            class="text-sm text-gray-500 mb-1"
            *ngIf="formGroup.value.subtype === 'SU'"
          >
            Recommended aspect ratio is 4:3 or 16:10.
          </div>
        </div>

        <button
          type="button"
          (click)="uploader.click()"
          class="text-primary text-4xl mr-2"
        >
          <app-icon icon="photo"></app-icon>
        </button>
      </div>

      <app-form-control-error-layout
        [control]="formGroup.get('text')"
      ></app-form-control-error-layout>
    </label>
  </div>
  <div
    class="my-5"
    *ngIf="formGroup.value.type == 'MC' || formGroup.value.type == 'PO'"
  >
    <label>
      <span class="form-label">Display options as</span>

      <div class="flex items-center">
        <select class="form-control" formControlName="subtype">
          <option *ngFor="let option of displayOptions" [value]="option.value">
            {{ option.name }}
          </option>
        </select>
      </div>

      <app-form-control-error-layout
        [control]="formGroup.get('subtype')"
      ></app-form-control-error-layout>
    </label>
  </div>
  <div class="my-5" *ngIf="formGroup.value.type == 'SA'">
    <label>
      <span class="form-label">Choose answer input type</span>

      <div class="flex items-center">
        <select class="form-control" formControlName="subtype">
          <option *ngFor="let option of inputTypes" [value]="option.value">
            {{ option.name }}
          </option>
        </select>
      </div>

      <app-form-control-error-layout
        [control]="formGroup.get('subtype')"
      ></app-form-control-error-layout>
    </label>
  </div>
  <div class="my-10">
    <!-- if multiple choices -->
    <app-exam-item-form-choices
      *ngIf="
        formGroup.value.type == 'MC' ||
        formGroup.value.type == 'IC' ||
        formGroup.value.type == 'CB' ||
        (formGroup.value.type == 'PO' && formGroup.value.subtype !== 'SU')
      "
      [formGroup]="formGroup"
    ></app-exam-item-form-choices>

    <app-exam-item-form-shortanswer
      *ngIf="formGroup.value.type == 'SA'"
      [formGroup]="formGroup"
    >
    </app-exam-item-form-shortanswer>

    <app-exam-item-form-file-upload
      *ngIf="formGroup.value.type == 'ME'"
      d
      [formGroup]="formGroup"
    >
    </app-exam-item-form-file-upload>

    <ng-container *ngIf="formGroup.value.subtype === 'SU'">
      <label>
        <span class="form-label">Comment Box Text</span>
        <input
          formControlName="instructions"
          class="form-control"
          placeholder="Text for comment box"
        />
      </label>
    </ng-container>
  </div>

  <div class="my-10">
    <label class="flex mb-4" *ngIf="formGroup.get('required').enabled">
      <app-checkbox
        class="mr-2 text-primary text-2xl leading-none"
        [formControl]="formGroup.controls['required']"
      ></app-checkbox>

      <div>
        <div class="form-label">Required</div>
        <div class="text-sm text-gray-600 leading-none">
          Learner is required to answer this item
        </div>
      </div>
    </label>

    <label class="flex mb-4" *ngIf="formGroup.get('isGraded').enabled">
      <app-checkbox
        class="mr-2 text-primary text-2xl leading-none"
        [formControl]="formGroup.controls['isGraded']"
      ></app-checkbox>

      <div>
        <div class="form-label">Graded</div>
        <div class="text-sm text-gray-600 leading-none">
          This item is graded and will be added to the total grade.
        </div>
      </div>
    </label>

    <ng-container
      *ngIf="
        formGroup.value.type == 'MC' ||
        formGroup.value.type == 'IC' ||
        (formGroup.value.type == 'PO' && formGroup.value.subtype !== 'SU')
      "
    >
      <label class="flex mb-4">
        <app-checkbox
          class="mr-2 text-primary text-2xl leading-none"
          [formControl]="formGroup.controls['shuffleChoices']"
        ></app-checkbox>

        <div>
          <div class="form-label">Shuffle Choices</div>
          <div class="text-sm text-gray-600 leading-none">
            Choices will be shuffled
          </div>
        </div>
      </label>
    </ng-container>
  </div>

  <div class="my-5" *ngIf="formGroup.get('showResults').enabled">
    <label class="flex">
      <app-checkbox
        class="mr-2 text-primary text-2xl leading-none"
        [formControl]="formGroup.controls['showResults']"
      ></app-checkbox>
      <div class="mt-1">
        <div class="form-label leading-none">Show Result</div>
        <div
          class="text-sm font-italic"
          [ngClass]="{
            'text-gray-400': !formGroup.get('showResults').value
          }"
        >
          App will show if the user correctly answers this item
        </div>
        <button
          type="button"
          (click)="onEditFeedback()"
          *ngIf="formGroup.get('showResults').value"
          class="text-primary-600"
        >
          Edit Feedback
        </button>
      </div>
    </label>
  </div>
  <!-- <ng-container *ngIf="formGroup.value.type == 'FW'">
      <div class="my-10">
        <label class="flex">
          <app-checkbox
            class="mr-2 text-primary text-2xl leading-none"
            formControlName="required"
          ></app-checkbox>

          <div class="mt-1">
            <div class="form-label leading-none">
              Share All Responses to Students
            </div>
            <div class="text-sm text-gray-600">
              After answering, Student can view other student’s response
            </div>
          </div>
        </label>
      </div>
      </ng-container> -->

  <div
    class="my-10"
    *ngIf="
      formGroup.value.type == 'MC' ||
      formGroup.value.type == 'IC' ||
      formGroup.value.type == 'PO'
    "
  >
    <!-- <div class="my-5">
            <label>
              <span class="form-label">Weight</span>

              <input
                type="number"
                formControlName="weight"
                class="form-control"
              />
            </label>
            <app-icon icon=""></app-icon>
          </div> -->
  </div>
</div>
